<template>
  <div>

    <b-card>
      <b-row>
        <!-- <b-col /> -->
        <b-col
          cols="12"
          class="mb-1"
        >
          <b-button
            variant="gradient-success"
            class="mr-sm-25"
            size="sm"
            @click="preAdd"
          >
            <feather-icon icon="PlusIcon" /> Tambah Campaign
          </b-button>
        </b-col>
        <b-col cols="12">
          <vue-good-table
            mode="remote"
            :total-rows="totalRecords"
            :is-loading.sync="isLoading"
            :pagination-options="{
              enabled: true,
            }"
            :rows="rows"
            :columns="columns"
            :sort-options="{
              enabled: false,
            }"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            @on-per-page-change="onPerPageChange"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >

              <!-- Column: Cito -->
              <span v-if="props.column.field === 'action'">
                <b-button
                  variant="gradient-success"
                  class="btn-icon mr-sm-25"
                  size="sm"
                  @click="preUpdate(props.row)"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>

                <b-button
                  variant="gradient-warning"
                  class="btn-icon"
                  size="sm"
                  @click="deleteElection(props.row.id)"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>

              </span>
              <span v-else-if="props.column.field === 'banner'">
                <b-button
                  variant="gradient-success"
                  class="btn-icon"
                  size="sm"
                  @click="showModalHtml(imgHtml(props.row.banner), 'banner')"
                >
                  <feather-icon icon="EyeIcon" />
                </b-button>
              </span>
              <span v-else-if="props.column.field === 'description'">
                <b-button
                  variant="gradient-success"
                  class="btn-icon"
                  size="sm"
                  @click="showModalHtml(props.row.description, 'description')"
                >
                  <feather-icon icon="EyeIcon" />
                </b-button>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>

            <!-- Column: Common -->

            </template>
          </vue-good-table>
        </b-col>
      </b-row>
    </b-card>
    <ModalContentHtml
      :is-open.sync="modalHtml.isOpen"
      :title="modalHtml.title"
      :content="modalHtml.content"
    />
    <ModalCampaign
      :is-open.sync="modalCampaign.isOpen"
      :action-type="modalCampaign.typeActionModal"
      :auto-fill-form="modalCampaign.dataModalForm"
      :selected-candidates="modalCampaign.selectedCandidates"
      @actionDone="loadItems"
    />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'
import { debounce } from 'debounce'
import API from '@/api'
import ModalContentHtml from './components/ModalContentHtml.vue'
import ModalCampaign from './components/ModalCampaign.vue'

export const joinToQuery = (key, value) => {
  if (!value) {
    return ''
  }

  return `${key}=${value}`
}

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    ModalContentHtml,
    ModalCampaign,
  },
  data() {
    return {
      isLoading: false,
      columns: [
      // Name, Faculty, Date Start, Date End, Created At, Updated At
        {
          label: 'candidate id',
          field: 'candidate_id',
        },
        {
          label: 'Banner',
          field: 'banner',
        },
        {
          label: 'description',
          field: 'description',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: '',
            type: '',
          },
        ],
        page: 1,
        perPage: 10,
      },
      filters: {
        search: '',
        start_at: '',
        finish_at: '',
      },
      modalHtml: {
        isOpen: false,
        title: '',
        content: '',
      },
      modalCampaign: {
        isOpen: false,
        dataModalForm: null,
        typeActionModal: 'add',
        selectedCandidates: [],
      },
    }
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.onFilters()
      },
    },
  },
  created() {
    this.loadItems()
  },
  methods: {
    imgHtml(url) {
      return `
        <img src="${url}" alt="banner" width="320" height="320">
      `
    },
    showModalHtml(content, title) {
      this.modalHtml = {
        isOpen: true,
        title,
        content,
      }
    },
    // eslint-disable-next-line func-names
    onFilters: debounce(function () {
      this.loadItems()
    }, 200),
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems()
    },

    onSortChange(params) {
      this.updateParams({
        sort: [{
          type: params.sortType,
          field: this.columns[params.columnIndex].field,
        }],
      })
      this.loadItems()
    },

    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },

    // load items is what brings back the rows from server
    async loadItems() {
      try {
        this.isLoading = true
        // start_at=2018-08-08 00:00:00&finish_at=2018-08-08 00:00:00&searh=iluni
        // qs += joinToQuery('limit', this.serverParams.perPage)
        // qs += joinToQuery('page', this.serverParams.page)
        const { data } = await API.campaign.getCampaign()
        this.modalCampaign.selectedCandidates = data.data.map(item => item.candidate_id)
        this.totalRecords = data.total
        this.rows = data.data
      } catch (error) {
        //
      } finally {
        this.isLoading = false
      }
    },
    preUpdate(campaign) {
      this.modalCampaign = {
        dataModalForm: {
          ...campaign,
          banner: null,
        },
        typeActionModal: 'update',
        isOpen: true,
        selectedCandidates: [],
      }
    },
    preAdd() {
      this.modalCampaign = {
        ...this.modalCampaign,
        typeActionModal: 'add',
        isOpen: true,
        selectedCandidates: this.rows.map(item => item.candidate_id),
      }
    },
    async deleteElection(id) {
      if ((await this.$_customConfirm({ text: 'Apakah anda yakin, ingin menghapus campaign?' }))) {
        try {
          this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', true)
          await API.campaign.deleteCampaign(id)
          this.loadItems()
          this.$_handleMsgSuccess('Berhasil menghapus campaign')
        } catch (error) {
          this.$_handleMsgErrors(error, 'Gagal menghapus campaign')
        } finally {
          this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', false)
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
