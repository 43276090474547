<template>
  <b-modal
    ref="modalCampaign"
    :title="`${actionType === 'add' ? 'Tambah' : 'Update'} campaign`"
    ok-title="Simpan"
    cancel-title="Batalkan"
    no-close-on-backdrop
    no-close-on-esc
    size="lg"
    @hide="closeModal"
    @ok="submit"
  >
    <ValidationObserver
      ref="formCampaign"
      tag="form"
    >

      <b-form-group
        label="Candidate"
        label-for="candidate"
      >

        <ValidationProvider
          #default="{ errors }"
          name="candidate"
          rules="required"
        >
          <v-select
            ref="form-candidate-id"
            v-model="form.candidate_id"
            :disabled="actionType === 'update'"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="nama"
            :options="candidate.selectOptions"
            :reduce="candidate => candidate.id"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </ValidationProvider>
      </b-form-group>

      <b-form-group
        label="Banner"
        label-for="banner"
      >

        <ValidationProvider
          #default="{ errors }"
          name="banner"
          rules=""
        >
          <b-form-file
            :value="form.banner"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
            @change="uploadImage"
          />

          <small class="text-danger">{{ errors[0] }}</small>
        </ValidationProvider>
      </b-form-group>

      <b-form-group
        label="Description"
        label-for="description"
      >

        <ValidationProvider
          #default="{ errors }"
          name="description"
          rules="required"
        >
          <quill-editor
            v-model="form.description"
          />

          <small class="text-danger">{{ errors[0] }}</small>
        </ValidationProvider>
      </b-form-group>

    </ValidationObserver>
    <hr>
  </b-modal>
</template>

<script>
import { BFormFile } from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'
import { quillEditor } from 'vue-quill-editor'
import API from '@/api'

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

const formObj = {
  // id: null,
  candidate_id: null,
  banner: null,
  description: '',
}

export default {

  components: {
    vSelect,
    quillEditor,
    BFormFile,
  },

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    actionType: {
      type: String,
      default: 'add',
      validator(value) {
        // The value must match one of these strings
        return ['add', 'update'].indexOf(value) !== -1
      },
    },

    autoFillForm: {
      type: Object,
      default: () => ({ ...formObj }),
    },

    selectedCandidates: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      form: { ...formObj },
      candidate: {
        data: [],
        selectOptions: [],
        fetch: 'idle',
      },
      required,
    }
  },
  watch: {
    isOpen(val) {
      if (val) {
        this.form = { ...formObj }
        if (this.actionType === 'update') {
          this.form = this.autoFillForm
        }
        this.$refs.modalCampaign.show()
      }
    },
    selectedCandidates(val) {
      this.candidate.selectOptions = this.candidate.data.data.filter(item => !val.includes(item.id))
    },
  },
  created() {
    this.fetchCandidate()
  },
  mounted() {
    // this.$refs.modalCampaign.show()
  },
  methods: {
    closeModal() {
      this.$emit('update:is-open', false)
    },

    resetForm() {
      this.form = { ...formObj }
    },

    uploadImage(e) {
      const { files } = e.target
      // eslint-disable-next-line prefer-destructuring
      this.form.banner = files[0]
    },

    async add() {
      if ((await this.$_customConfirm({ text: 'Apakah anda yakin, ingin menambahkan campaign?' }))) {
        try {
          this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', true)
          const formData = new FormData()

          // eslint-disable-next-line no-restricted-syntax, guard-for-in
          for (const key in this.form) {
            console.log(key)
            formData.append(key, this.form[key])
          }

          await API.campaign.createCampaign(formData)
          this.$emit('actionDone')
          this.$refs.modalCampaign.hide()
          this.resetForm()
          this.$_handleMsgSuccess('Berhasil menambahkan campaign')
        } catch (error) {
          console.log(error)
          this.$_handleMsgErrors(error, 'Gagal menambahkan campaign')
        } finally {
          this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', false)
        }
      }
    },

    async update() {
      if ((await this.$_customConfirm({ text: 'Apakah anda yakin, ingin menyimpan perubahan?' }))) {
        try {
          this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', true)
          const formData = new FormData()

          if (!this.form.banner) {
            delete this.form.banner
          }

          // eslint-disable-next-line no-restricted-syntax, guard-for-in
          for (const key in this.form) {
            console.log(key)
            formData.append(key, this.form[key])
          }

          await API.campaign.updateCampaign(formData)
          this.$emit('actionDone')
          this.$refs.modalCampaign.hide()
          this.resetForm()
          this.$_handleMsgSuccess('Berhasil menyimpan perubahan campaign')
        } catch (error) {
          console.log(error)
          this.$_handleMsgErrors(error, 'Gagal menyimpan perubahan campaign')
        } finally {
          this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', false)
        }
      }
    },

    async submit(e) {
      e.preventDefault()
      if (await this.$_checkValidateOfForm('formCampaign')) {
        this[this.actionType]()
      }
    },

    async fetchCandidate() {
      try {
        this.candidate.fetching = 'pending'
        const { data } = await API.candidate.getCandidate('limit=50')
        this.candidate.data = data
        this.candidate.selectOptions = data.data.filter(item => !this.selectedCandidates.includes(item.id))
        this.candidate.fetching = 'resolved'
      } catch (error) {
        console.log(error)
        this.candidate.fetching = 'rejected'
      }
    },

  },

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
